import { Flex, Heading, Text } from "@chakra-ui/react";
import { Event } from "types/api";


function LogEvent(props: { event: Event }) {
    const data = JSON.parse(props?.event.eventData);
    return (<>
        <Text>{data.condition}</Text>
        <Heading size='md' mt='16px' mb='4px'>Stack Trace</Heading>
        <pre style={{ whiteSpace: 'pre-wrap' }}>{data.stacktrace}</pre>
    </>);
}

function SceneLoadSubEvent(props: { event: Event }) {
    const data = JSON.parse(props?.event.eventData);
    return (<Flex direction='column'>
        <b>Scene</b>
        <Text>{data.sceneName}</Text>
    </Flex>);
}

function GenericSubEvent(props: { event: Event }) {
    const data = JSON.parse(props?.event.eventData);
    return (<Flex direction='column'>
        <b>Name</b>
        <Text>{data.name}</Text>
    </Flex>);
}

function CollectedSubEvent(props: { event: Event }) {
    const data = JSON.parse(props?.event.eventData);
    return (<Flex direction='column'>
        <b>Name</b>
        <Text mb='12px'>{data.name}</Text>
        <b>Already Collected</b>
        <Text>{data.collectedCount}</Text>
    </Flex>);
}

function VolumeSubEvent(props: { event: Event }) {
    const data = JSON.parse(props?.event.eventData);
    return (<Flex direction='column'>
        <b>Volume</b>
        <Text mb='12px'>{data.volume}</Text>
    </Flex>);
}


const dataComponents: { [id: string]: any } = {
    'LogEvent': LogEvent,
    'SceneLoadSubEvent': SceneLoadSubEvent,
    'GenericSubEvent': GenericSubEvent,
    'CollectedSubEvent': CollectedSubEvent,
    'VolumeSubEvent': VolumeSubEvent,
};

export default function EventData(props: { event: Event }) {
    const { event } = props;

    console.log(event.eventType);

    if (event.eventType in dataComponents) {
        const Component = dataComponents[event.eventType];
        return (<Component event={event} />);
    }


    return (<pre style={{ whiteSpace: 'pre-wrap' }}>
        {JSON.stringify(JSON.parse(event.eventData), null, 2)}
    </pre>
    );
}

