import { useAuth } from "contexts/AuthContext";
import { Route, useHistory } from "react-router-dom";

export function ProtectedRoute({ ...rest }) {
    const { user } = useAuth();
    const history = useHistory();

    if (!user || !user.token || user.token === '') {
        history.push('/auth/sign-in');
        return (<></>);
    }
    return (<Route {...rest} />);
}