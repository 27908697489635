import { QuestionIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Heading, Icon, SimpleGrid, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import { PlayersApi } from "api/analytics";
import Card from "components/card/Card";
import DetailRow from "components/DetailRow";
import ErrorAlert from "components/ErrorAlert";
import IconBox from "components/icons/IconBox";
import LoadingIndicator from "components/LoadingIndicator";
import Page from "components/Page";
import Pagination from "components/Pagination";
import { useEffect, useState } from "react";
import { MdAccessTime, MdCancel, MdCheckCircle, MdEvent, MdHourglassBottom, MdLockClock, MdPhoneAndroid, MdPlayLesson, MdQuestionAnswer } from "react-icons/md";
import { NavLink, useParams } from "react-router-dom";
import { PaginationState, Player, Session } from "types/api";
import { formatDate, formatPlaytime } from "utils";

export default function PlayerDetails() {

    const { id } = useParams<{ id: string }>();

    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

    const textColorSecondary = useColorModeValue('secondaryGray.800', 'secondaryGray.500');


    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSessions, setIsLoadingSessions] = useState(true);
    const [error, setError] = useState(undefined);
    const [player, setPlayer] = useState<Player>(undefined);
    const [sessions, setSessions] = useState<Session[]>([]);
    const [pagination, setPagination] = useState<PaginationState>(undefined);

    const fetchSessions = (page: number) => {
        setIsLoadingSessions(true);
        PlayersApi.sessions(player.id.toString(), page).then(
            (res) => {
                setIsLoadingSessions(false);
                setSessions(res.data);
                setPagination(JSON.parse(res.headers['x-pagination']));
            },
            (error) => {
                setIsLoadingSessions(false);
                setError(error);
            }
        );
    }

    useEffect(() => {
        setIsLoading(true);
        PlayersApi.get(id).then(
            (res) => {
                setIsLoading(false);
                setPlayer(res.data);
            },
            (error) => {
                setIsLoading(false);
                setError(error);
            }
        );
    }, []);


    useEffect(() => {
        if (player) {
            fetchSessions(1);
        }
    }, [player]);

    return (<Page>
        <SimpleGrid columns={{ base: 1, xl: 2 }} gap='20px' mb='20px'>

            <ErrorAlert error={error} />

            <Card>
                <LoadingIndicator loading={isLoading}>
                    <>
                        <Heading size='xl' mb='8px'>
                            Player {id}
                        </Heading>
                        <DetailRow icon={MdPhoneAndroid} title='Device Id' content={player?.guid} />
                        <DetailRow icon={QuestionIcon} title='Last Seen' content={formatDate(player?.lastSeen)} />
                        <DetailRow icon={QuestionIcon} title='First Seen' content={formatDate(player?.firstSeen)} />
                        <DetailRow icon={QuestionIcon} title='Sessions' content={player?.sessionCount?.toString()} />
                        <DetailRow icon={QuestionIcon} title='Play Duration' content={formatPlaytime(player?.playtime ?? 0)} />
                        {/* <DetailRow icon={QuestionIcon} title='Latest Version' content='0.0.3' /> TODO */}
                    </>
                </LoadingIndicator>
            </Card>
            <Box></Box>
        </SimpleGrid>
        <Card>
            <Heading size='md'>
                Latest Sessions
            </Heading>
            <LoadingIndicator loading={isLoadingSessions}>
                <>
                    <TableContainer>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Id</Th>
                                    <Th>Start</Th>
                                    <Th>End</Th>
                                    <Th>Duration</Th>
                                    <Th>Events</Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {sessions?.map((session) => (

                                    <Tr key={session.id}>
                                        <Td>
                                            {session.guid}
                                        </Td>
                                        <Td>
                                            {formatDate(session.start)}
                                        </Td>
                                        <Td>
                                            {formatDate(session.end)}
                                        </Td>
                                        <Td>
                                            {formatPlaytime(session.playtime)}
                                        </Td>
                                        <Td>
                                            {session.eventCount.toString()}
                                        </Td>
                                        <Td>
                                            <NavLink to={'/analytics/players/' + player?.id + '/sessions/' + session.id} >
                                                <Button size='sm' variant='outline'>Details</Button>
                                            </NavLink>
                                        </Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TableContainer>
                    <Pagination pagination={pagination} onChangePage={fetchSessions} />
                </>
            </LoadingIndicator>
        </Card>
    </Page >);
}