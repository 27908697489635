import { SimpleGrid } from "@chakra-ui/react";
import Card from "components/card/Card";
import Page from "components/Page";
import { NavLink } from "react-router-dom";

export default function CGPAnalysis() {

    const gobbos = [
        {
            id: '0',
            name: 'Gergor 1'
        },
        {
            id: '1',
            name: 'Gergor 2'
        },
        {
            id: '2',
            name: 'Gergor 3'
        },
        {
            id: '3',
            name: 'Mux 1'
        },
        {
            id: '4',
            name: 'Mux 2'
        }
    ];
    const blights = [
        {
            id: '6',
            name: 'Sutorial'
        },
        {
            id: '7',
            name: 'Spass'
        },
        {
            id: '8',
            name: 'Shygh'
        },
        {
            id: '9',
            name: 'Spike'
        },
        {
            id: '10',
            name: 'Sutup'
        }
    ];

    return (
        <Page>
            <SimpleGrid columns={{
                base: 1,
                md: 2,
                xl: 5
            }} gap='20px' mb='20px'>
                {
                    gobbos.map((item) => (
                        <NavLink to={'/analytics/cgps/' + item.id} key={item.id}>
                            <Card>
                                {item.name}
                            </Card>
                        </NavLink>
                    ))
                }
            </SimpleGrid>
            <NavLink to='/analytics/cgps/5'>
                <Card mb='20px'>
                    Tonstar
                </Card>
            </NavLink>
            <SimpleGrid columns={{
                base: 1,
                md: 2,
                xl: 5
            }} gap='20px' mb='20px'>
                {
                    blights.map((item) => (
                        <NavLink to={'/analytics/cgps/' + item.id} key={item.id}>
                            <Card>
                                {item.name}
                            </Card>
                        </NavLink>
                    ))
                }
            </SimpleGrid>
        </Page>
    );
}