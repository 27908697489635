
import { Text, useColorModeValue } from '@chakra-ui/react';


export default function CardTitle({ text }: { text: string }) {
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    return (
        <Text color={textColorPrimary} fontWeight='bold' fontSize='2xl' mt='10px' mb='16px'>
            {text}
        </Text>
    );
}

