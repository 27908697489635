import { Button, Flex, Heading, Icon, SimpleGrid, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import Card from "components/card/Card";
import MiniStatistics from "components/card/MiniStatistics";
import Page from "components/Page";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { NavLink, useParams } from "react-router-dom";

export default function CGPDetails() {

    const { id } = useParams<{ id: string }>();

    return (
        <Page>
            <Card mb='20px'>
                <Heading>CGP {id}</Heading>
                <Flex align='center' style={{ display: 'none' }}>
                    <Icon
                        w='24px'
                        h='24px'
                        me='5px'
                        color={
                            /*  info.getValue() === 'Approved' ? (*/
                            'green.500'
                            /*) : info.getValue() === 'Disable' ? (
                                'red.500'
                            ) : info.getValue() === 'Error' ? (
                                'orange.500'
                            ) : null*/
                        }
                        as={
                            //info.getValue() === 'Approved' ? (
                            MdCheckCircle
                            /*) : info.getValue() === 'Disable' ? (
                                MdCancel
                            ) : info.getValue() === 'Error' ? (
                                MdOutlineError
                            ) : null*/
                        }
                    />
                    <Text fontSize='sm' fontWeight='700'>
                        Success
                    </Text>
                </Flex>
            </Card>
            <SimpleGrid columns={4} gap='20px' mb='20px'>
                <MiniStatistics name='Succeded' value='100' />
                <MiniStatistics name='Failed' value='10' />
                <MiniStatistics name='Average Duration' value='132 s' />
                <MiniStatistics name='Average Steps' value='7.4' />
            </SimpleGrid>
            <Card>
                <TableContainer>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Date</Th>
                                <Th>Try</Th>
                                <Th>Result</Th>
                                <Th>Device</Th>
                                <Th>Steps</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>2023-02-20</Td>
                                <Td>1</Td>
                                <Td>
                                    <Flex align='center'>
                                        <Icon
                                            w='24px'
                                            h='24px'
                                            me='5px'
                                            color={
                                                'red.500'
                                            }
                                            as={
                                                MdCancel
                                            }
                                        />
                                        <Text fontSize='sm' fontWeight='700'>
                                            Failure
                                        </Text>
                                    </Flex>
                                </Td>
                                <Td>baba8fdf-ba29-4d5c-a2b2-13a13bd36e3b</Td>
                                <Td>5</Td>
                                <Td>
                                    <NavLink to={'/analytics/cgps/' + id + '/tries/0'} >
                                        <Button size='sm' variant='outline'>Details</Button>
                                    </NavLink>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td>2023-02-20</Td>
                                <Td>2</Td>
                                <Td>
                                    <Flex align='center'>
                                        <Icon
                                            w='24px'
                                            h='24px'
                                            me='5px'
                                            color={
                                                'green.500'
                                            }
                                            as={
                                                MdCheckCircle
                                            }
                                        />
                                        <Text fontSize='sm' fontWeight='700'>
                                            Success
                                        </Text>
                                    </Flex>
                                </Td>
                                <Td>baba8fdf-ba29-4d5c-a2b2-13a13bd36e3b</Td>
                                <Td>10</Td>
                                <Td>
                                    <NavLink to={'/analytics/cgps/' + id + '/tries/1'} >
                                        <Button size='sm' variant='outline'>Details</Button>
                                    </NavLink>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </Card>
        </Page>
    );
}