import { Alert, AlertIcon, Button, FormControl, FormLabel, Input, Spinner } from '@chakra-ui/react';
import AuthApi from 'api/auth';
import Card from 'components/card/Card';
import CardTitle from 'components/card/CardTitle';
import Page from 'components/Page';
import { useState } from 'react';

export default function Profile() {

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
    const [error, setError] = useState(undefined);
    const [isChanging, setIsChanging] = useState(false);
    const [isPasswordChanged, setIsPasswordChanged] = useState(false);

    const changePassword = async () => {
        if (oldPassword === '') {
            return setError('Old password is empty.');
        }
        if (newPassword === '') {
            return setError('New password is empty.');
        }
        if (newPasswordRepeat === '') {
            return setError('Repeat new password is empty.');
        }
        if (newPassword !== newPasswordRepeat) {
            return setError('New password and repeat new password are not the same.');
        }
        if (oldPassword === newPassword) {
            return setError('Old and new password are the same.')
        }

        setIsChanging(true);

        try {
            let response = await AuthApi.changePassword({ oldPassword, newPassword });
            setIsChanging(false);
            if (response.data.success === true) {
                setIsPasswordChanged(true);
                setOldPassword('');
                setNewPassword('');
                setNewPasswordRepeat('');
            }
        } catch (err: any) {
            console.log(err);
            setIsChanging(false);
            if (err.response && err.response.data && err.response.data.message) {
                return setError(err.response.data.message);
            }
            return setError('There has been an error.');
        }
    };

    return (
        <Page>
            <Card maxW='450px'>
                <CardTitle text='Change Password' />
                {error &&
                    <Alert status='error' mb='16px'>
                        <AlertIcon />
                        {error}
                    </Alert>
                }
                {isPasswordChanged &&
                    <Alert status='success' mb='16px'>
                        <AlertIcon />
                        Password was changed.
                    </Alert>
                }

                <FormControl isRequired isDisabled={isChanging}>
                    <FormLabel>
                        Old Password
                    </FormLabel>
                    <Input
                        type='password'
                        placeholder='Old Password'
                        defaultValue={oldPassword}
                        onChange={(event) => {
                            setOldPassword(event.target.value);
                            setError(undefined);
                            setIsPasswordChanged(false);
                        }}
                        mb='16px'
                    />
                </FormControl>

                <FormControl isRequired isDisabled={isChanging}>
                    <FormLabel>
                        New Password
                    </FormLabel>
                    <Input
                        type='password'
                        placeholder='New Password'
                        defaultValue={newPassword}
                        onChange={(event) => {
                            setNewPassword(event.target.value);
                            setError(undefined);
                            setIsPasswordChanged(false);
                        }}
                        mb='16px'
                    />
                </FormControl>

                <FormControl isRequired isDisabled={isChanging}>
                    <FormLabel>
                        Repeat New Password
                    </FormLabel>
                    <Input
                        type='password'
                        placeholder='New Password'
                        defaultValue={newPasswordRepeat}
                        onChange={(event) => {
                            setNewPasswordRepeat(event.target.value);
                            setError(undefined);
                            setIsPasswordChanged(false);
                        }}
                        mb='24px'
                    />
                </FormControl>

                <Button variant='brand' disabled={isChanging} onClick={changePassword}>
                    {
                        isChanging ? <><Spinner me='16px' />Changing Password...</> : 'Change Password'
                    }
                </Button>
            </Card>
        </Page>
    );
}