import { EventsApi } from "api/events";
import Card from "components/card/Card";
import ErrorAlert from "components/ErrorAlert";
import EventsTable from "components/EventsTable";
import LoadingIndicator from "components/LoadingIndicator";
import Page from "components/Page";
import Pagination from "components/Pagination";
import { useEffect, useState } from "react";
import { Event, PaginationState } from "types/api";

export default function EventList() {

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const [events, setEvents] = useState<Event[]>([]);
    const [pagination, setPagination] = useState<PaginationState>(undefined);

    const fetchEvents = (page: number) => {
        setIsLoading(true);
        EventsApi.all(page).then(
            (res) => {
                setIsLoading(false);
                setEvents(res.data);
                setPagination(JSON.parse(res.headers['x-pagination']));
            },
            (error) => {
                setIsLoading(false);
                setError(error);
            }
        );
    }

    useEffect(() => {
        fetchEvents(1);
    }, []);

    return (<Page>
        <Card>
            <ErrorAlert error={error} />
            <LoadingIndicator loading={isLoading}>
                <>
                    <EventsTable events={events} />
                    <Pagination pagination={pagination} onChangePage={fetchEvents} />
                </>
            </LoadingIndicator>
        </Card>
    </Page>);
}