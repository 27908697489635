import { TableContainer, Table, Thead, Tr, Th, Tbody, Td, Button } from "@chakra-ui/react";
import events from "events";
import { NavLink } from "react-router-dom";
import { Event } from "types/api";
import { formatDate } from "utils";

export default function EventsTable(props: { events: Event[] }) {
    const { events } = props;
    return (
        <TableContainer>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Event Name</Th>
                        <Th>User Time</Th>
                        <Th>Device</Th>
                        <Th>Session</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {events?.map((event) => (
                        <Tr key={event.id}>
                            <Td>{event.name}</Td>
                            <Td>{formatDate(event.userTime)}</Td>
                            <Td>{event.deviceId}</Td>
                            <Td>{event.sessionId}</Td>
                            <Td>
                                <NavLink to={'/analytics/events/' + event.id}>
                                    <Button size='sm' variant='outline'>Details</Button>
                                </NavLink>
                            </Td>
                        </Tr>
                    ))}

                </Tbody>
            </Table>
        </TableContainer>
    );
}