import { Flex, Heading, Icon, SimpleGrid, space, Text, useColorModeValue } from "@chakra-ui/react";
import { EventsApi } from "api/events";
import Card from "components/card/Card";
import DetailRow from "components/DetailRow";
import ErrorAlert from "components/ErrorAlert";
import EventData from "components/EventData";
import IconBox from "components/icons/IconBox";
import LoadingIndicator from "components/LoadingIndicator";
import Page from "components/Page";
import { useEffect, useState } from "react";
import { MdAccessTime, MdEvent, MdHourglassBottom, MdLockClock, MdPhoneAndroid, MdPlayLesson } from "react-icons/md";
import { NavLink, useParams } from "react-router-dom";
import { Event } from "types/api";
import { formatDate } from "utils";

export default function EventDetails() {

    const { id } = useParams<{ id: string }>();

    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

    const textColorSecondary = useColorModeValue('secondaryGray.800', 'secondaryGray.500');

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const [event, setEvent] = useState<Event | undefined>(undefined);

    useEffect(() => {
        setIsLoading(true);
        EventsApi.get(id).then(
            (res) => {
                setIsLoading(false);
                setEvent(res.data);
            },
            (error) => {
                setIsLoading(false);
                setError(error);
            }
        )
    }, []);

    return (<Page>
        <SimpleGrid columns={{ base: 1, xl: 2 }} gap='20px'>
            <LoadingIndicator loading={isLoading}>
                <Card>
                    <ErrorAlert error={error} />
                    <Heading size='xl' mb='8px'>
                        Event {id}
                    </Heading>
                    <Flex gap='8px' my='4px'>
                        <IconBox
                            w='48px'
                            h='48px'
                            m='8px'
                            bg={boxBg}
                            icon={<Icon w='26px' h='26px' as={MdEvent} color={brandColor} />}
                        />
                        <Flex direction='column' justifyContent='space-evenly' my='8px'>
                            <b>Event Name</b>
                            {event?.name}
                        </Flex>
                    </Flex>
                    <Flex gap='8px' my='4px'>
                        <IconBox
                            w='48px'
                            h='48px'
                            m='8px'
                            bg={boxBg}
                            icon={<Icon w='26px' h='26px' as={MdAccessTime} color={brandColor} />}
                        />
                        <Flex direction='column' justifyContent='space-evenly' my='8px'>
                            <b>User Time</b>
                            {formatDate(event?.userTime)}
                        </Flex>
                    </Flex>
                    <NavLink to={'/analytics/players/' + event?.deviceId}>
                        <DetailRow icon={MdPhoneAndroid} title='Device' content={event?.deviceId} />
                    </NavLink>
                    <NavLink to={'/analytics/players/' + event?.deviceId + '/sessions/' + event?.sessionId}>
                        <DetailRow icon={MdPlayLesson} title='Session' content={event?.sessionId} />
                    </NavLink>
                </Card>
            </LoadingIndicator>
            {event && event.eventData &&
                <Card>
                    <Heading size='lg'>
                        Extra Data
                    </Heading>
                    <Text fontSize='lg' mb='8px' color={textColorSecondary}>{event?.eventType}</Text>

                    <EventData event={event} />
                </Card>
            }
        </SimpleGrid>
    </Page >);
}