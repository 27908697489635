import { Alert, AlertIcon } from "@chakra-ui/react";

export default function ErrorAlert(props: { error: any/*Error*/ }) {
    var { error } = props;

    if (error) {
        var message = error.message;
        if (error.response && error.response.data && error.response.data.message) {
            message = error.response.data.message;
        }
        return (
            <Alert status='error' mb='16px'>
                <AlertIcon />
                {message}
            </Alert>
        )
    } else {
        return (<></>);
    }
}