import { Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import Card from "components/card/Card";
import Page from "components/Page";
import { NavLink } from "react-router-dom";

export default function ErrorList() {
    return (
        <Page>
            <Card>
                <TableContainer>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Exception</Th>
                                <Th>File</Th>
                                <Th>Occurrences</Th>
                                <Th>Last Seen</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>
                                    MissingComponentException
                                </Td>
                                <Td>DebugScreen.cs:24</Td>
                                <Td>1337</Td>
                                <Td>2023-02-20 23:03:36</Td>
                                <Td>
                                    <NavLink to={'/analytics/errors/0'}>
                                        <Button size='sm' variant='outline'>Details</Button>
                                    </NavLink>
                                </Td>
                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </Card>
        </Page>
    );
}