import Axios from 'axios';
import { API_SERVER } from 'config/constant';

const axios = Axios.create({
    baseURL: API_SERVER,
    headers: { 'Content-Type': 'application/json' }
});

axios.interceptors.request.use(
    (config) => {
        return Promise.resolve(config);
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {
        return Promise.reject(error);
    }
);

export const setAuthToken = (token: string | undefined) => {
    if (token) {
        axios.defaults.headers.common["Authorization"] = token;
    }
    else
        delete axios.defaults.headers.common["Authorization"];
}

export default axios;
