import { Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { PlayersApi } from "api/analytics";
import Card from "components/card/Card";
import ErrorAlert from "components/ErrorAlert";
import LoadingIndicator from "components/LoadingIndicator";
import Page from "components/Page";
import Pagination from "components/Pagination";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { PaginationState, Player } from "types/api";
import { formatDate, formatPlaytime } from "utils";

export default function PlayerList() {

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const [players, setPlayers] = useState<Player[]>([]);
    const [pagination, setPagination] = useState<PaginationState>(undefined);

    const fetchPlayers = (page: number) => {
        setIsLoading(true);
        PlayersApi.all(page).then(
            (res) => {
                setIsLoading(false);
                setPlayers(res.data);
                setPagination(JSON.parse(res.headers['x-pagination']));
            },
            (error) => {
                setIsLoading(false);
                setError(error);
            }
        );
    }

    useEffect(() => {
        fetchPlayers(1);
    }, []);

    return (
        <Page>
            <Card>
                <ErrorAlert error={error} />
                <LoadingIndicator loading={isLoading}>
                    <>
                        <TableContainer>
                            <Table>
                                <Thead>
                                    <Tr>
                                        <Th>Identifier</Th>
                                        <Th>Last Seen</Th>
                                        <Th>First Seen</Th>
                                        <Th>Sessions</Th>
                                        <Th>Playtime</Th>
                                        <Th></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {players?.map((player) => (
                                        <Tr key={player.id}>
                                            <Td>{player.name ?? player.guid}</Td>
                                            <Td>{formatDate(player.lastSeen)}</Td>
                                            <Td>{formatDate(player.firstSeen)}</Td>
                                            <Td>{player.sessionCount}</Td>
                                            <Td>{formatPlaytime(player.playtime)}</Td>
                                            <Td>
                                                <NavLink to={'/analytics/players/' + player.id} >
                                                    <Button size='sm' variant='outline'>Details</Button>
                                                </NavLink>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <Pagination pagination={pagination} onChangePage={fetchPlayers} />
                    </>
                </LoadingIndicator>
            </Card>
        </Page>
    );
}