import { Button, Flex } from "@chakra-ui/react";
import { PaginationState } from "types/api";

export default function Pagination(props: {
    pagination: PaginationState,
    onChangePage: (page: number) => void,
}) {
    const { pagination, onChangePage } = props;
    if (pagination) {
        return (<Flex gap='4px' mt='8px'>
            {
                Array(pagination.total_pages).fill(null).map((_, i) => {
                    const page = i + pagination.first_page;
                    if (page === pagination.page) {
                        return (
                            <Button key={i} variant='brand' cursor='default' size='sm'>{page}</Button>
                        )
                    } else {
                        return (
                            <Button key={i} size='sm' onClick={() => onChangePage(page)}>{page}</Button>
                        );
                    }

                })
            }

        </Flex>);
    } else {
        return (<></>);
    }
}