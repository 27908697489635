import { QuestionIcon } from "@chakra-ui/icons";
import { Button, Flex, Heading, Icon, SimpleGrid, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import DetailRow from "components/DetailRow";
import IconBox from "components/icons/IconBox";
import Page from "components/Page";
import { MdAccessTime, MdEvent, MdHourglassBottom, MdLockClock, MdPhoneAndroid, MdPlayLesson, MdQuestionAnswer } from "react-icons/md";
import { NavLink, useParams } from "react-router-dom";

export default function ErrorDetails() {

    const { id } = useParams<{ id: string }>();

    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

    const textColorSecondary = useColorModeValue('secondaryGray.800', 'secondaryGray.500');


    return (<Page>
        <SimpleGrid columns={{ base: 1, xl: 2 }} gap='20px' mb='20px'>
            <Card>
                <Heading size='xl' mb='8px'>
                    Error {id}
                </Heading>
                First Seen
                Last Seen
                Number of Occurrences
                Versions this error appears in
                Type of error
                Message of the error
                Full Stack Traces

            </Card>
            <Card>
            </Card></SimpleGrid>
        <Card>
            <Heading size='md'>
                Latest Occurrences
            </Heading>
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr>
                            <Th>Date</Th>
                            <Th>User</Th>
                            <Th>Session</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>2023-02-20 12:32</Td>
                            <Td>a3b76343-6887-4358-b7cf-3148b2f659b8</Td>
                            <Td>a3b76343-6887-4358-b7cf-3148b2f659b8</Td>
                            <Td>
                                <NavLink to={'/analytics/events/32'} >
                                    <Button size='sm' variant='outline'>Details</Button>
                                </NavLink>
                            </Td>
                        </Tr>
                    </Tbody>
                </Table>
            </TableContainer>
        </Card>
    </Page >);
}