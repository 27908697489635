import axios from "api";

class AuthApi {
    static Login = (data: any) => {
        return axios.post('users/login', data);
    }

    static Logout = (data: any) => {
        return axios.post('users/logout', data, { headers: { Authorization: data.token } });
    }

    static changePassword = (data: { oldPassword: string, newPassword: string }) => {
        return axios.post('users/password', data);
    }

    static check = () => {
        return axios.post('users/check');
    };
}

export default AuthApi;