import axios from "api";

const PAGE_SIZE = 50;
export class EventsApi {
    static all = (page: number) => {
        return axios.get(`insights/?page=${page}&page_size=${PAGE_SIZE}`);
    }

    static get = (id: string) => {
        return axios.get('insights/' + id);
    }

    static dashboard = () => {
        return axios.get('insights/dashboard');
    }

    static funnel = () => {
        return axios.get('insights/funnel');
    }
}