import { Flex, Icon, useColorModeValue } from "@chakra-ui/react";
import IconBox from "./icons/IconBox";

export default function DetailRow(props: {
    icon: any,
    title: string,
    content: JSX.Element | string
}) {
    const { icon, title, content } = props;
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');


    return (
        <Flex gap='8px' my='4px'>
            <IconBox
                w='48px'
                h='48px'
                m='8px'
                bg={boxBg}
                icon={<Icon w='26px' h='26px' as={icon} color={brandColor} />}
            />
            <Flex direction='column' justifyContent='space-evenly' my='8px'>
                <b>{title}</b>
                {content}
            </Flex>
        </Flex>
    );
}  