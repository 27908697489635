import { Box } from "@chakra-ui/react";
import { EventsApi } from "api/events";
import Card from "components/card/Card";
import ErrorAlert from "components/ErrorAlert";
import LoadingIndicator from "components/LoadingIndicator";
import Page from "components/Page";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from 'react-apexcharts';
import { XY } from "types/api";

export default function GameFunnel() {

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(undefined);
    const [steps, setSteps] = useState<XY[]>([]);

    const series = [{
        data: steps ?? []
    }];

    const options: ApexCharts.ApexOptions = {
        chart: {
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
                horizontal: true,
                dataLabels: {
                    position: 'bottom'
                }
            }
        },
        tooltip: {
            enabled: false,
            style: {
                fontSize: '12px',
                fontFamily: undefined
            },
            onDatasetHover: {
            },
        },
        dataLabels: {
            enabled: true,
            offsetX: 10,
            style: {
                colors: ['#ffffffc0']
            }
        },
        xaxis: {
            labels: {
                show: false
            },
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            }
        },
        yaxis: {
            labels: {
                show: true,
                style: {
                    colors: '#CBD5E0',
                    fontSize: '14px'
                },
                maxWidth: 300
            }
        },
        grid: {
            show: false,
        },
        fill: {
            type: 'gradient',
            gradient: {
                type: 'horizontal',
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                colorStops: [
                    [
                        {
                            offset: 0,
                            color: 'rgba(67, 24, 255, 1)',
                            opacity: 0.28
                        },
                        {
                            offset: 100,
                            color: '#4318FF',
                            opacity: 1
                        },
                    ]
                ]
            }
        },

    };

    useEffect(() => {
        setIsLoading(true);
        EventsApi.funnel().then(
            (res) => {
                setIsLoading(false);
                setSteps(res.data.steps);
            },
            (error) => {
                setIsLoading(false);
                setError(error);
            }
        );
    }, []);

    return (
        <Page>
            <Card>
                <ErrorAlert error={error} />
                <LoadingIndicator loading={isLoading}>
                    <Box height='780px'>
                        <Chart options={options} series={series} type='bar' width='100%' height='100%' />
                    </Box>
                </LoadingIndicator>
            </Card>
        </Page>
    );
}