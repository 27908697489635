import { createContext, useContext, useState } from "react";
import { User } from "types/api";

interface AuthContextType {
    user: User | null,
    setUser: (user: User) => void
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ userData, children }: { userData: User, children: any }) => {
    let [user, setUser] = useState<User | null>(userData);

    return <AuthContext.Provider value={{ user, setUser }
    }>
        {children}
    </AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);