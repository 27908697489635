import { Box, Icon, SimpleGrid, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import { EventsApi } from 'api/events';
import Card from 'components/card/Card';
import MiniStatistics from 'components/card/MiniStatistics';
import ErrorAlert from 'components/ErrorAlert';
import EventsTable from 'components/EventsTable';
import IconBox from 'components/icons/IconBox';
import LoadingIndicator from 'components/LoadingIndicator';
import Page from 'components/Page';
import { useEffect, useState } from 'react';
import { IoMdTrophy } from 'react-icons/io';
import { MdGroups, MdHourglassBottom, MdStars, MdVideogameAsset } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { DashboardState } from 'types/api';
import DailyActiveUsers from '../admin/default/components/DailyActiveUsers';


export default function UserReports() {
	// Chakra Color Mode
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(undefined);
	const [data, setData] = useState<DashboardState>(undefined);


	const dauData = [{
		name: 'Daily Active Users',
		data: [
			{ x: '2023-02-20', y: 2 },
			{ x: '2023-02-19', y: 1 },
			{ x: '2023-02-18', y: 1 },
			{ x: '2023-02-17', y: 0 },
			{ x: '2023-02-16', y: 0 },
			{ x: '2023-02-15', y: 0 },
			{ x: '2023-02-14', y: 0 },
			{ x: '2023-02-13', y: 0 },
			{ x: '2023-02-12', y: 0 },
			{ x: '2023-02-12', y: 0 },
			{ x: '2023-02-14', y: 0 },
			{ x: '2023-02-13', y: 0 },
			{ x: '2023-02-12', y: 0 },
			{ x: '2023-02-12', y: 0 },
		]
	}];



	const mauData = [{
		name: 'Monthly Active Users',
		data: [
			{ x: '2023-02', y: 2 },
			{ x: '2023-01', y: 0 },
			{ x: '2022-12', y: 0 },
			{ x: '2022-11', y: 0 },
			{ x: '2022-10', y: 0 },
			{ x: '2022-09', y: 0 }
		]
	}];

	useEffect(() => {
		setIsLoading(true);
		EventsApi.dashboard().then(
			(res) => {
				setIsLoading(false);
				setData(res.data);
			},
			(error) => {
				setIsLoading(false);
				setError(error);
			}
		)
	}, []);


	return (
		<Page>
			<LoadingIndicator loading={isLoading}>
				<>
					<ErrorAlert error={error} />
					{/* <Center>
				<Spinner size='xl' />
			</Center>
			<Stack>
				<Skeleton height='20px' />
				<Skeleton height='20px' />
				<Skeleton height='20px' />
			</Stack> */}
					{/* <SimpleGrid columns={{ base: 1, md: 3, lg: 3, '2xl': 6 }} gap='20px' mb='20px'> */}


					<SimpleGrid columns={{ base: 1, md: 2, lg: 4, }} gap='20px' mb='20px'>
						{/* <MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdPerson} color={brandColor} />}
						/>
					}
					name='DAU'
					value='2'
				/>

				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdPeople} color={brandColor} />}
						/>
					}
					name='MAU'
					value='16'
				/> */}



						<MiniStatistics
							startContent={
								<IconBox
									w='56px'
									h='56px'
									bg={boxBg}
									icon={<Icon w='32px' h='32px' as={MdVideogameAsset} color={brandColor} />}
								/>
							}
							name='Currently Active Users'
							value={data?.currentlyActive}
						/>

						<MiniStatistics
							startContent={
								<IconBox
									w='56px'
									h='56px'
									bg={boxBg}
									icon={<Icon w='32px' h='32px' as={MdGroups} color={brandColor} />}
								/>
							}
							name='All Time Users'
							value={data?.allTimeUsers}
						/>


						{/* <MiniStatistics
							startContent={
								<IconBox
									w='56px'
									h='56px'
									bg={boxBg}
									icon={<Icon w='32px' h='32px' as={MdHourglassBottom} color={brandColor} />}
								/>
							}
							name='Total Playtime'
							value={`${data?.totalPlaytime ?? 0} h`}
						/> */}

						<MiniStatistics
							startContent={
								<IconBox
									w='56px'
									h='56px'
									bg={boxBg}
									icon={<Icon w='32px' h='32px' as={IoMdTrophy} color={brandColor} />}
								/>
							}
							name='Completed Games'
							value={data?.completedGames}
						/>

						<MiniStatistics
							startContent={
								<IconBox
									w='56px'
									h='56px'
									bg={boxBg}
									icon={<Icon w='32px' h='32px' as={MdStars} color={brandColor} />}
								/>
							}
							name='100% Achieved'
							value={data?.fullAchievements}
						/>

						{/*
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />}
						/>
					}
					name='Spend this month'
					value='$642.39'
				/>
				<MiniStatistics growth='+23%' name='Sales' value='$574.34' />
				<MiniStatistics
					endContent={
						<Flex me='-16px' mt='10px'>
							<FormLabel htmlFor='balance'>
								<Avatar src={Usa} />
							</FormLabel>
							<Select id='balance' variant='mini' mt='5px' me='0px' defaultValue='usd'>
								<option value='usd'>USD</option>
								<option value='eur'>EUR</option>
								<option value='gba'>GBA</option>
							</Select>
						</Flex>
					}
					name='Your balance'
					value='$1,000'
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
							icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
						/>
					}
					name='New Tasks'
					value='154'
				/>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />}
						/>
					}
					name='Total Projects'
					value='2935'
				/> */}
					</SimpleGrid>



					<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
						<DailyActiveUsers title='Daily Active Users' value={data?.dau} data={data?.daus} />
						<DailyActiveUsers title='Monthly Active Users' value={data?.mau} data={data?.maus} />



						{/* <TotalSpent />
				<WeeklyRevenue /> */}
					</SimpleGrid>



					<Card>
						<NavLink to="/analytics/events">
							<Text color={brandColor} fontSize='xl' fontWeight='700'>
								Latest Events
							</Text>
						</NavLink>
						<EventsTable events={data?.events} />
					</Card>
					<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
						{/* <CheckTable tableData={tableDataCheck} />
				<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
					<DailyTraffic />
					<PieCard />
				</SimpleGrid> */}
					</SimpleGrid>
					<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
						{/* <ComplexTable tableData={tableDataComplex} />
				<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
					<Tasks />
					<MiniCalendar h='100%' minW='100%' selectRange={false} />
				</SimpleGrid> */}
					</SimpleGrid>
				</>
			</LoadingIndicator>
		</Page>
	);
}
