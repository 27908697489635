// Chakra imports
import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import BarChart from 'components/charts/BarChart';

// Custom components
import Card from 'components/card/Card';
import { barChartDataDailyTraffic, barChartOptionsDailyTraffic } from 'variables/charts';

// Assets
import { RiArrowUpSFill } from 'react-icons/ri';

export default function DailyActiveUsers(props: { [x: string]: any }) {
	const { title, value, data, ...rest } = props;

	const textColor = useColorModeValue('secondaryGray.900', 'white');

	if (data === undefined) {
		return (<></>);
	}

	const series = [{
		name: title,
		data: data ?? []
	}];
	return (
		<Card alignItems='center' flexDirection='column' w='100%' {...rest}>
			<Flex justify='space-between' align='start' px='10px' pt='5px' w='100%'>
				<Flex flexDirection='column' align='start' me='20px'>
					<Flex w='100%'>
						<Text me='auto' color='secondaryGray.600' fontSize='sm' fontWeight='500'>
							{title}
						</Text>
					</Flex>
					<Flex align='end'>
						<Text color={textColor} fontSize='34px' fontWeight='700' lineHeight='100%'>
							{value}
						</Text>
					</Flex>
				</Flex>
			</Flex>
			<Box h='240px' mt='auto' w='100%'>
				<BarChart chartData={series} chartOptions={barChartOptionsDailyTraffic} />
			</Box>
		</Card>
	);
}
