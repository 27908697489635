import axios from "api";



const PAGE_SIZE = 20;

export class PlayersApi {
    static all = (page: number) => {
        return axios.get(`players/?page=${page}&page_size=${PAGE_SIZE}`);
    }

    static get = (id: string) => {
        return axios.get(`players/${id}`);
    }

    static sessions = (id: string, page: number) => {
        return axios.get(`players/${id}/sessions?page=${page}&page_size=${PAGE_SIZE}`);
    }
}