import { QuestionIcon } from "@chakra-ui/icons";
import { Button, Flex, Heading, Icon, SimpleGrid, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
import DetailRow from "components/DetailRow";
import EventsTable from "components/EventsTable";
import IconBox from "components/icons/IconBox";
import Page from "components/Page";
import { MdAccessTime, MdCancel, MdCheckCircle, MdEvent, MdHourglassBottom, MdLockClock, MdPhoneAndroid, MdPlayLesson, MdQuestionAnswer } from "react-icons/md";
import { NavLink, useParams } from "react-router-dom";

export default function SessionDetails() {

    const { id, sessionId } = useParams<{ id: string, sessionId: string }>();

    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

    const textColorSecondary = useColorModeValue('secondaryGray.800', 'secondaryGray.500');


    return (<Page>
        <SimpleGrid columns={{ base: 1, xl: 2 }} gap='20px' mb='20px'>
            <Card>
                <Heading size='xl' mb='8px'>
                    Player {id} / Session {sessionId}
                </Heading>

                <DetailRow icon={QuestionIcon} title='Start' content='2023-02-21 00:12:32' />
                <DetailRow icon={QuestionIcon} title='End' content='2023-02-18 17:42:10' />
                <DetailRow icon={QuestionIcon} title='Duration' content='10 min' />
                <DetailRow icon={QuestionIcon} title='Events' content='0.0.3' />
                <DetailRow icon={QuestionIcon} title='Version' content='0.0.3' />
            </Card>
            {/* <Card>
            </Card> */}
        </SimpleGrid>
        <Card>
            <Heading size='md'>
                Events
            </Heading>
            <EventsTable events={[]} />
        </Card>
    </Page >);
}