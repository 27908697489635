import { Icon } from '@chakra-ui/react';
import { MdBarChart, MdPerson, MdHome, MdLock, MdOutlineShoppingCart, MdDashboard, MdList, MdFilter, MdFilterAlt, MdBugReport, MdGames, MdPeople } from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/analytics/Dashboard';
import NFTMarketplace from 'views/admin/marketplace';
import DataTables from 'views/admin/dataTables';
import RTL from 'views/admin/rtl';

// Auth Imports
import SignInCentered from 'views/auth/signIn';
import EventList from 'views/analytics/EventList';
import Profile from 'views/analytics/Profile';
import EventDetails from 'views/analytics/EventDetails';
import GameFunnel from 'views/analytics/GameFunnel';
import CGPAnalysis from 'views/analytics/CGPAnalysis';
import PlayerList from 'views/analytics/PlayerList';
import ErrorList from 'views/analytics/ErrorList';
import CGPDetails from 'views/analytics/CGPDetails';
import CGPTryDetails from 'views/analytics/CGPTryDetails';
import PlayerDetails from 'views/analytics/PlayerDetails';
import ErrorDetails from 'views/analytics/ErrorDetails';
import SessionDetails from 'views/analytics/SessionDetails';

const routes: {
	name: string;
	layout: string;
	path: string;
	icon: JSX.Element;
	excluded?: boolean;
	component: () => JSX.Element;
}[] = [
		{
			name: 'Dashboard',
			layout: '/analytics',
			path: '/dashboard',
			icon: <Icon as={MdDashboard} width='20px' height='20px' color='inherit' />,
			component: MainDashboard
		},
		{
			name: 'Event Details',
			layout: '/analytics',
			path: '/events/:id',
			icon: <Icon as={MdList} width='20px' height='20px' color='inherit' />,
			excluded: true,
			component: EventDetails
		},
		{
			name: 'Event List',
			layout: '/analytics',
			path: '/events',
			icon: <Icon as={MdList} width='20px' height='20px' color='inherit' />,
			component: EventList
		},
		{
			name: 'Game Funnel',
			layout: '/analytics',
			path: '/funnel',
			icon: <Icon as={MdFilterAlt} width='20px' height='20px' color='inherit' />,
			component: GameFunnel
		},
		{
			name: 'Core Gameplay Try Details',
			layout: '/analytics',
			path: '/cgps/:id/tries/:tryId',
			icon: <Icon as={MdGames} width='20px' height='20px' color='inherit' />,
			excluded: true,
			component: CGPTryDetails
		},
		{
			name: 'Core Gameplay Details',
			layout: '/analytics',
			path: '/cgps/:id',
			icon: <Icon as={MdGames} width='20px' height='20px' color='inherit' />,
			excluded: true,
			component: CGPDetails
		},
		{
			name: 'Core Gameplays',
			layout: '/analytics',
			path: '/cgps',
			icon: <Icon as={MdGames} width='20px' height='20px' color='inherit' />,
			excluded: true, // TODO
			component: CGPAnalysis
		},
		{
			name: 'Session Details',
			layout: '/analytics',
			path: '/players/:id/sessions/:sessionId',
			icon: <Icon as={MdPeople} width='20px' height='20px' color='inherit' />,
			excluded: true,
			component: SessionDetails
		},
		{
			name: 'Player Details',
			layout: '/analytics',
			path: '/players/:id',
			icon: <Icon as={MdPeople} width='20px' height='20px' color='inherit' />,
			excluded: true,
			component: PlayerDetails
		},
		{
			name: 'Players',
			layout: '/analytics',
			path: '/players',
			icon: <Icon as={MdPeople} width='20px' height='20px' color='inherit' />,
			excluded: true, // TODO
			component: PlayerList
		},
		{
			name: 'Error Details',
			layout: '/analytics',
			path: '/errors/:id',
			icon: <Icon as={MdBugReport} width='20px' height='20px' color='inherit' />,
			excluded: true,
			component: ErrorDetails
		},
		{
			name: 'Errors',
			layout: '/analytics',
			path: '/errors',
			icon: <Icon as={MdBugReport} width='20px' height='20px' color='inherit' />,
			excluded: true, // TODO
			component: ErrorList
		},
		{
			name: 'Profile',
			layout: '/analytics',
			path: '/profile',
			icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
			excluded: true,
			component: Profile
		},
		// {
		// 	name: 'NFT Marketplace',
		// 	layout: '/admin',
		// 	path: '/nft-marketplace',
		// 	icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />,
		// 	component: NFTMarketplace,
		// 	secondary: true
		// },
		// {
		// 	name: 'Data Tables',
		// 	layout: '/admin',
		// 	icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
		// 	path: '/data-tables',
		// 	component: DataTables
		// },
		// {
		// 	name: 'Profile',
		// 	layout: '/admin',
		// 	path: '/profile',
		// 	icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
		// 	component: Profile
		// },
		{
			name: 'Sign In',
			layout: '/auth',
			path: '/sign-in',
			icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
			excluded: true,
			component: SignInCentered
		},
		// {
		// 	name: 'RTL Admin',
		// 	layout: '/rtl',
		// 	path: '/rtl-default',
		// 	icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		// 	component: RTL
		// }
	];

export default routes;
