import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { AuthProvider } from 'contexts/AuthContext';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { setAuthToken } from 'api';
import ScrollToTop from 'components/scrollbar/ScrollToTop';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
	dsn: "https://2642ff08e4ab4aafb7d1f1485d0d5f14@o4504746855170048.ingest.sentry.io/4504746905501696",
	integrations: [new BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

const user = JSON.parse(localStorage.getItem('user'));
// TODO first check if the token is still valid and redirect to sign in page if it is not.
setAuthToken(user?.token);

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<AuthProvider userData={user}>
			<React.StrictMode>
				<BrowserRouter>

					<ScrollToTop />
					<Switch>
						<Route path={`/auth`} component={AuthLayout} />
						<ProtectedRoute path={`/analytics`} component={AdminLayout} />
						<Route path={`/rtl`} component={RTLLayout} />
						<Redirect from='/' to='/auth/sign-in' />
					</Switch>
				</BrowserRouter>
			</React.StrictMode>
		</AuthProvider>
	</ChakraProvider>,
	document.getElementById('root')
);
