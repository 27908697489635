import { Center, Spinner } from "@chakra-ui/react";

export default function LoadingIndicator(props: {
    loading: boolean,
    children: JSX.Element
}) {
    const { loading, children } = props;

    if (loading) {
        return (<Center><Spinner size='xl' /></Center>);
    } else {
        return children;
    }
}