import { Heading, ListItem, OrderedList, SimpleGrid } from "@chakra-ui/react";
import Card from "components/card/Card";
import DetailRow from "components/DetailRow";
import Page from "components/Page";
import { IoFootsteps } from "react-icons/io5";
import { MdCheck, MdCheckCircle, MdList, MdPhoneAndroid, MdPlayLesson, MdStairs, MdTimelapse, MdTimeline, MdTimeToLeave, MdWatch } from "react-icons/md";
import { NavLink, useParams } from "react-router-dom";

export default function CGPTryDetails() {
    const { id, tryId } = useParams<{ id: string, tryId: string }>();
    return (
        <Page>
            <SimpleGrid columns={{ base: 1, xl: 2 }} gap='20px'>
                <Card>
                    <Heading size='xl' mb='8px'>CGP {id} / Try {tryId}</Heading>
                    <DetailRow icon={MdCheckCircle} title='Result' content='Success' />
                    <DetailRow icon={IoFootsteps} title='Steps' content='5' />
                    <DetailRow icon={MdTimelapse} title='Duration' content='123 s' />
                    <NavLink to='/analytics/users/1'>
                        <DetailRow icon={MdPhoneAndroid} title='Device' content='baba8fdf-ba29-4d5c-a2b2-13a13bd36e3b' />
                    </NavLink>
                    <NavLink to='/analytics/users/1/sessions/2'>
                        <DetailRow icon={MdPlayLesson} title='Session' content='a4dd2f5f-437b-437f-9b2f-88c55656cc82' />
                    </NavLink>
                </Card>
                <Card>
                    <Heading size='lg' mb='8px'>
                        Steps
                    </Heading>
                    <OrderedList>
                        <ListItem>Left</ListItem>
                        <ListItem>Wave</ListItem>
                        <ListItem>Right</ListItem>
                        <ListItem>Left</ListItem>
                        <ListItem>Left</ListItem>
                        <ListItem>Left</ListItem>
                    </OrderedList>
                </Card>
            </SimpleGrid>
        </Page>
    )
};